.toggle-form {
  flex: 1 1;
  justify-items: flex-end;
  position: absolute;
  top: 10px;
  right: 25px;
}

.toggle-form button {
  text-transform: none;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  padding: 5px 10px;
}

.modal-body .comment-note {
  width: 99%;
}

.modal-footer {
  gap: 10px;
}

.modal-footer .modal-button {
  text-transform: none !important;
  padding: 8px 17px !important;
  font-family: 'Source Sans Pro', sans-serif;
  border-radius: 4px;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.modal-footer .confirm-button {
  background-color: #007bff !important;
  color: #fff !important;
}

.modal-footer .cancel-button {  
  background-color: #fcfcfc !important;
  color: rgba(0, 0, 0, 0.82) !important;
}

.read-only-permissions-label {
  flex: 1 1;
  align-self: flex-end;
  justify-items: flex-end;
  position: absolute;
  top: 10px;
  right: 25px;
  text-transform: none;
  color: #1976d2;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
}

